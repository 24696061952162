<template lang="pug">
.main-container
	.container
		.master
			.information
				.Getting-here
					h1 Getting Here
				.address
					|TIME SQUARE RESORT &amp; SPA <br />
					|The Villa <br />
					|Opp. Suryavarsani School, <br />
					|Bhuj- Mundra Road, <br />
					|Kutch - Gujarat <br />
					|Pincode : 370427
				.tel Tel:
					a(href="tel:+91 93288 51092") +91-93288 51092
				.view-in-maps(@click="openMaps") VIEW IN MAPS
			.the-map
				iframe(src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.623345047326!2d69.62184181535994!3d23.147438917457407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3951215e6436d609%3A0xacfb8fc1043a0c66!2sTime%20Square%20Club%20Resort%20%26%20Spa!5e0!3m2!1sen!2sin!4v1665811951207!5m2!1sen!2sin",style="border:0;",allowfullscreen="",loading="lazy",referrerpolicy="no-referrer-when-downgrade")
</template>

<script>

export default {
  methods: {
    openMaps() {
      window.open("https://www.google.com/maps/place/Time+Square+Club+Resort+%26+Spa/@23.1474389,69.6218418,17z/data=!4m8!3m7!1s0x3951215e6436d609:0xacfb8fc1043a0c66!5m2!4m1!1i2!8m2!3d23.147434!4d69.6240305")
    }
  }
}
</script>

<style scoped>

.main-container {
    background-color: #d7d5d0;
    font-family: "Muli", "sans-serif";
	padding: 5rem 0rem;
}

.master{
  	display: flex;
    justify-content: center;
	align-items: center;
    gap: 100px;
	text-align: right;
}


h1 {
  font-weight: 100;
  font-family: "DM Serif Text", "sans-serif";
}

.view-in-maps {
	float: right;
  border-radius: 2px;
  width: 150px;
  display: grid;
  place-items: center;
  aspect-ratio: 3;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  margin-top: 20px;
  cursor: pointer;
}
.the-map {
  color: rgb(14, 14, 14);
  width: 650px;
}

.the-map iframe {
    width: 100%;
    aspect-ratio: 1.5;
}

@media(max-width: 1024px) {
  
.main-container {
  padding: 100px 50px;
    flex-direction: column;
}

.master{
	
    flex-direction: column;
}
}
</style>