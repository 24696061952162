<template>
  <main>
    <a class="whatsappWrapper" href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!"><img src="../assets/img/WhatsApp.svg"/></a>
    <!-- =================================== -->
    <!-- Booking Widget -->
    <!-- =================================== -->

    <div id="booking" class="gridWrapper">
      <div class="widget">
        <div
          id="bookingwidgetBEwidget_zJBmM6cGgIsrpCdCs4cKBTFyNmrgJAUfBb0c4+KlI9c="
        ></div>
        <component
          id="BEJSGROUP"
          :is="'script'"
          data-BEJSGroupFilePath="https://maxbe.staah.net/widget/BEwidget/mainwidgetbe.js"
          async
        ></component>
      </div>
    </div>
    <!-- <div class="container call-us">
      <h2>Kindly call us at: <a style="font-weight: 100; opacity: 0.5;" href="tel:+91 79847 59430">+91 79847 59430</a> for room booking</h2>
    </div> -->
	<!-- =================================== -->
	<!-- Booking Widget End -->
	<!-- =================================== -->

	<!-- =================================== -->
	<!-- Main Cover Header -->
	<!-- =================================== -->

  <div class="main-hero-vid">
    <video muted autoplay loop>
      <source :src="getRandomVideo()" type="video/mp4">
    </video>
  </div>
	<!-- =================================== -->
	<!-- Main Cover Header End -->
	<!-- =================================== -->


      <!-- =================================== -->
      <!-- Image Raw Section -->
      <!-- =================================== -->

      <div style="background:#d7d5d0" class="image-raw-section">
        <div class="container">
			
          <div class="image-grid">
			<router-link  to="/Rooms">
            <div class="image-card">
              <div class="main-image">
                <img
                  :src="require('@/assets/img/real_site_images/resized/deluxe_king2.jpg')"
                  alt="Deluxe Room"
                />
              </div>
              <div class="image-name">Deluxe Room</div>
            </div>
		</router-link>
<router-link  to="/Rooms">
            <div class="image-card">
              <div class="main-image">
                <img
                  :src="require('@/assets/img/real_site_images/resized/suite_living room.jpg')"
                  alt="Junior Suite"
                />
              </div>
              <div class="image-name">Junior Suite</div>
            </div>
			</router-link>
<router-link  to="/Rooms">
            <div class="image-card">
              <div class="main-image">
                <img
                  :src="require('@/assets/img/real_site_images/resized/suite_balcony_cropped.jpg')"
                  alt="Deluxe Suite"
                />
              </div>
              <div class="image-name">Deluxe Suite</div>
            </div>
		  </router-link>
          </div>
        </div>
		
      <div class="clearfix"></div>
      </div>
      <!-- Image Raw Section End -->



    <amenities-on-site :images="images"></amenities-on-site>

<!-- Avail Great Offer Section STart -->
	  <div style="padding-top: 0px" class="main-hero">
      <div style="width: 100%; background: #111" class="block-1">
        <div class="container">
          <div class="content">
            <h1>
              Avail Great Offers And Packages <br />
              by Becoming A Club Member.
            </h1>
            <br />

            <user-information />
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
    </div>
<!-- Avail Great Offer Section End -->
<location></location>
  </main>
  <!-- /main -->
</template>

<script>

import Location from '../components/Location.vue'

function loadBookingWidget() {
  if (localStorage.getItem("BEJSGROUP") === null) {
    var BEJSGroupFilePath_VARVN = "1.0.0.0";
  } else {
    var BEJSGroupFilePath_VARVN = localStorage.getItem("BEJSGROUP");
  }
  var displayViewIdsElement = document
    .getElementById("BEJSGROUP")
    .getAttribute("data-BEJSGroupFilePath");
  document.getElementById("BEJSGROUP").src =
    displayViewIdsElement + "?v=" + BEJSGroupFilePath_VARVN;
  window.addEventListener("load", function () {
    callGroupWidget("zJBmM6cGgIsrpCdCs4cKBTFyNmrgJAUfBb0c4+KlI9c=");
    console.log(
      document.querySelectorAll(".fte-bootstrap-wrapper .text-left ")
    );
  });
}

import AmenitiesOnSite from "../components/AmenitiesOnSite.vue";
import UserInformation from '../components/formComponents/userInformation.vue';

export default {
  data() {
    return {
      video: [require('@/assets/videos/05005 Rooms.mp4'), 
      require('@/assets/videos/01001 Restaurant Shamiyana Cafe.mp4'),
      require('@/assets/videos/02002  Pool Table Tenis Board Games.mp4'), 
      require('@/assets/videos/03003 Uplode Video.mp4'), 
      require('@/assets/videos/04004 Bowling Discothet.mp4'), 
      require('@/assets/videos/05005 Rooms.mp4')],

      images: [
        {
      src: require("@/assets/img/real_site_images/resized/swimming_pool3.jpg"),
          alt: "swimming pool, pool, swimming",
          text: "Swimming Pool",
        },
        {
		  src: require("@/assets/img/real_site_images/resized/fitness centre_2.jpg"),
          alt: "Gym, Fitness, Workout",
          text: "Gym",
        },
        {
          src: require("@/assets/overscans/sports.jpg"),
          alt: "sports, tennis, volleyball",
          text: "Sports",
        },
        {
          src: require("@/assets/img/final_3d_images/low/img026.jpg"),
          alt: "Hammam, spa",
          text: "Hammam & Spa",
        },
        {
          src: require("@/assets/img/real_site_images/resized/namak3.jpg"),
          alt: "Dining, Lunch, Dinner, Restaurant",
          text: "Dining",
        },
        {
          src: require("@/assets/overscans/bowling.jpg"),
          alt: "Games, bowling, pool, indoor games",
          text: "Indoor Games",
        },
        {
          src: require("@/assets/img/final_3d_images/low/img027.jpg"),
          alt: "Lawn tennis,Outdoor Sports",
          text: "Outdoor Sports",
        },
        {
          src: require("@/assets/img/final_3d_images/low/img086.jpg"),
          alt: "Games, , Badminton, indoor Sports",
          text: "Indoor Sports",
        },
        {
          src: require("@/assets/img/real_site_images/resized/Disco.jpg"),
          alt: "Experiences,Discotheque",
          text: "Experiences",
        },
        {
          src: require("@/assets/img/real_site_images/resized/the ballroom_setup.jpg"),
          alt: "Meeting & Weddings",
          text: "Meeting & Weddings",
        },
      ],
    };
  },
  name: "Home",
  components: {
    AmenitiesOnSite,Location,
    UserInformation
  },
  methods: {
    handleVideoClick() {
      window.location.href = "https://youtu.be/Ippaxdqnq3k";
    },
    getRandomVideo() {
      const random_number = Math.floor(Math.random() * 6);
      if (random_number == 6) {random_number = 5}

      console.log("Random")
      console.log(random_number)

      return this.video[random_number]

    }
  },
  mounted() {
    loadBookingWidget();
  },
};
</script>

<style scoped>

@media(max-width:600px) {
  .main-hero-vid {
    /* aspect-ratio: 1.77; */
  }
}

.grid {
  display: grid;
  place-items: center;
}

.gridWrapper {
  display: grid;
  width: 100%;
  place-items: center;
}

.widget {
  width: 100%;
  /* border: 2px solid #d4c7ae; */
  min-width: 400px;
  display: flex;
  justify-content: center;
  /* box-shadow: #d4c7aebf 5px 5px 12px 0px; */
  background-color: #af8e57;
}

.fte-bootstrap-wrapper .text-left {
  margin: 0;
}

/* ================================= */
/* image-raw-section */
/* ================================= */

.image-raw-section .image-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  color: black;
  font-weight: 400;
  padding: 200px 0;
}

.image-raw-section .image-card {
  overflow: hidden;
  border-radius: 3px;
}

.image-raw-section .main-image {
  height: 250px;
}

.image-raw-section .main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 2;
}

.image-raw-section .image-name {
  background-color: white;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.13);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

@media (max-width: 800px) {

	h1{ 
		font-size: 2.5rem !important;
	}

  .image-raw-section .image-grid {
    flex-direction: column;
  }
}

/* ================================= */
/* image-raw-section end */
/* ================================= */

.call-us {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
}
</style>