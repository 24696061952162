<template>
  <header class="header">
    <div class="container">
      <router-link to="/">
        <div class="logo-container">
          <img src="../assets/img/logo.png" alt="Time Square Logo" class="logo" />
        </div>
      </router-link>

      <!-- <a href="#" @click="handleOpenBarClick" class="open-nav open-nav-inverse"><i></i></a> -->

      <nav class="navigation">
        <ul>
			<li>
            <router-link to="/Rooms">Rooms</router-link>
			</li>
          <li>
            <router-link to="/Club">Amenities</router-link>
          </li>
          <li><router-link  to="/Dining">Dining</router-link></li>
          <li><router-link  to="/Experiences">Experiences</router-link></li>
          <li><router-link  to="/MeetingsWeddings">Meetings & Weddings</router-link></li>
          <li><router-link  to="/SpaHammam">Spa & Hammam</router-link></li>
          <li><router-link  to="/Contact">Contact</router-link></li>
          <li><router-link  to="/Events">Events</router-link></li>
        </ul>
      </nav>
      <!-- /.navigation -->

      <!-- <div class="clearfix"></div> -->
    </div>
  </header>
  <!-- /.header -->
  <!-- /.container -->
</template>



<script>


export default {
  setup(){

  },
  methods:{
    handleOpenBarClick: function(){
      this.$emit('toggleBar');
    }
  }
};
</script>

<style scoped>
.logo-container {
  padding: 20px 0px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  max-width: none;
  width: 100%;
  padding: 0 100px;
}


.navigation ul {
  display: flex;
  gap: 40px;
}

@media(max-width:1600px) {
  .container {
    flex-direction: column;
  }
  
  .header .container  {
    margin: 0px !important;
  }

  .logo {
    margin-top: 50px;
  }

  .navigation ul {
    padding: 50px 0px;
    gap: 0;
  }

  li {
  padding: 0px 30px;

  }

}


@media(max-width:1230px) {
  
  .navigation ul {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    
  }

  .navigation ul li {
    padding: 20px 30px;
    width: 200px;
  }
}

@media(max-width:800px) {

  .navigation ul {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width:420px) {
  .navigation ul li {
    padding: 20px 30px;
    width: 160px;
  }
}


li{
  position: relative;
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 2px;
  list-style: none;
  text-transform: uppercase;
  width: max-content;
}


.logo {
    height: 80px;
    
    /* min-width: 250px; */
}


.social-links {
    bottom: 35px;
    left: 70px;
    position: absolute;
}

.social-links li {
    float: left;
    font-family: Montserrat, sans-serif;
    font-size: 0.6875rem;
    font-weight: 500;
    letter-spacing: 3px;
    list-style: none;
    margin-right: 50px;
    text-transform: uppercase;
}

</style>