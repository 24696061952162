<template>
<div class="container"  style="padding-top:100px">
    <h2>FEATURED AMENITIES ON-SITE</h2>
    <flickity ref="flickity" :options="flickityOptions" class="amenities-container">
        
    <div v-for="image in images" class="amenities" :key="image.src"> 
        <div class="overlay"><h2>{{image.text}}<span v-if="image.text == 'Hammam & Spa'"><br/>(Hammam Opening Soon)</span></h2></div>
        <img :src="image.src" :alt="image.alt">
    </div>

    </flickity>
</div>
</template>

<script>

import Flickity from 'vue-flickity';

export default {
    data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        pageDots: true,
        wrapAround: true,
        imagesLoaded: true,
        prevNextButtons: true,
        draggable:true
    // any options from Flickity can be used
      }
    }
  },

props:{
    images:{
        type:Object,
        required:true
    }
},
components: {
    Flickity
  }
}

</script>

<style scoped>

.amenities-container {
	margin: 100px 0px;
    height: 600px;
}
.flickity-prev-next-button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
.amenities {
    position: relative;
    width: 100%;
    height: 600px;
    border-radius: 6px;
	overflow: hidden;
}


.amenities img{
width: 100%;
height: 100%;
object-fit: cover;
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
    background-color: rgb(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
}

.overlay h2 {
    font-size: 2rem;
    font-family: Montserrat,sans-serif;
    font-weight: 300;
    letter-spacing: 2.5px;
	margin: 0;
	margin-top: 0 !important;
    text-transform: uppercase;
}


@media(max-width:800px) {
    .amenities-container {
    grid-template-columns: 1fr 1fr;
}

.overlay h2 {
    font-size: 1.5rem;
    font-family: Montserrat,sans-serif;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
}

}
</style>