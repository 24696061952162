<template>
  <div class="container margin-handle">
    <div class="personalDetails">
      <div class="customDetails">
        <v-form :validation-schema="schema" @submit="onSubmit">
          
          <h4>Register for Club Membership!</h4>
            <!-- First Name -->
          <div class="field-container">
            <div class="label">Name *</div>
            <div class="input-container">
              <v-field class="input" name="Name" type="text"></v-field>
              <error-message class="message" name="Name"></error-message>
            </div>
          </div>

          <!-- Email -->
          <div class="field-container">
            <div class="label">Email Address *</div>
            <div class="input-container">
              <v-field class="input" name="email" type="text"></v-field>
              <error-message class="message" name="email"></error-message>
            </div>
          </div>

          <!-- mobile -->
          <div class="field-container">
            <div class="label">Mobile No. *</div>
          <div class="input-container">
            <v-field class="input" name="mobile" type="text"></v-field>
            <error-message class="message" name="mobile"></error-message>
          </div>
          </div>

          <div class="btnsArea">
            <button class="submit-btn">Enroll Now!</button>
          </div>

        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
// Import Libraries
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { sendMail } from "../../mail";


export default {
  components: {
    // Components were renamed to avoid conflicts of HTML form element without a vue compiler
    VForm: Form,
    VField: Field,
    ErrorMessage: ErrorMessage,
  },
  data() {
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    return {
      schema: Yup.object().shape({
        Name: Yup.string().required(),
        email: Yup.string().email().required(),
        mobile: Yup.string().matches(phoneRegExp, "Phone number is not valid").required(),
      })
    };
  },
  methods: {
    onSubmit(values) {
      console.log("Clicked")
      sendMail(values);
    }
  },
  props: ["title"],
};
</script>

<style scoped>

.margin-handle {
  margin: 50px auto;
}

.customDetails form {
  display: flex;
  flex-direction: column;
  align-items: center;

}

h4 {
    font-weight: 200;
    margin-bottom: 40px;
    font-size: 1.2rem;
    text-align: center;
}

.field-container{
  margin-bottom: 30px;
}

span {
  display: block;
  margin: 10px 0;
}

.headTitle {
  font-size: 2.5rem;
  font-family: Montserrat;
  text-align: center;
  margin: 50px;
}

.btnsArea {
  display: flex;
}


.input {
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 15px 10px;
  outline: none;
  height: 40px;
  background-color: #f2f5f7;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  width: 400px;
}


@media(max-width:500px)      {
  .input {
    width: 320px;
  }

  .margin-handle {
  margin: 50px 0px;
} 
}

.input:focus {
  border-color: dodgerblue;
}

.message {
  color: crimson;
  font-size: 0.8rem;
  margin: 0;
}

.submit-btn {
  background: dodgerblue;
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  display: block;
  width: 200px;
  border-radius: 7px;
  margin: auto;
  margin-top: 20px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  
}


.personalDetails {
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>
