import {createApp}from 'vue'
import App from './App.vue'

import router from './router.js'

import './assets/css/main.css'
import './assets/css/colors.css'
import './assets/css/responsive.css'
import './assets/css/themify-icons.css'
import './assets/css/custom.css'



createApp(App).use(router).mount('#app');
