<template>
  <div id="view-loader">
    <!-- Header -->

    <Navbar />

    <router-view />

    <Footer />

  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import ResponsiveNav from "./components/ResponsiveNav.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    ResponsiveNav,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Default Title",
    // all titles will be injected into this template
    titleTemplate: "%s | My Awesome Webapp",
  },
  data(){
    let isResNavOpen = false;
    return {isResNavOpen};
  },
  mounted() {

    let previews = {};
    document
      .querySelectorAll(".entry-thumbs > div")
      .forEach(function (element) {
        previews[element.getAttribute("data-entry-thumb")] = element;
      });

    console.log(previews);
    document
      .querySelectorAll(".entries-list div h1")
      .forEach(function (element) {
        console.log(element);
        element.addEventListener("mouseover", function (e) {
          let selectedIndex = element.getAttribute("data-entry-link");

          document
            .querySelector(".entries-list .active")
            .classList.remove("active");
          element.childNodes[0].classList.add("active");

          document
            .querySelector(".entry-thumbs .active")
            .classList.remove("active");
          previews[selectedIndex].classList.add("active");
        });
      });

    console.log("Reported");
  },
};
</script>

<style>
.header {
  background-color: #fff;
}
</style>