<template>
  	<!-- ========== Responsive Navigation ========== -->
	
	<div class="responsive-nav-container" :class="{ active: isResNavOpen }">
		
		<a href="#" @click="handleCloseBarClick" class="close-nav"><i class="ti-close"></i></a>

		<nav class="responsive-nav">
		<ul>
			<li>
            <router-link   @click="handleCloseBarClick" to="/">Rooms</router-link>
			</li>
          <li><router-link @click="handleResNavClick" to="/Club">The Club</router-link></li>
          <li><router-link  @click="handleResNavClick" to="/Dining">Dining</router-link></li>
          <li><router-link  @click="handleResNavClick" to="/Experiences">Experiences</router-link></li>
          <li><router-link  @click="handleResNavClick" to="/MeetingsWeddings">Meetings & Weddings</router-link></li>
          <li><router-link @click="handleResNavClick"  to="/SpaHammam">Spa & Hammam</router-link></li>
          <li><router-link  @click="handleResNavClick" to="/Contact">Contact</router-link></li>
        </ul>
		</nav> <!-- /.responsive-nav -->

		<ul class="social-links">
			<li><a href="http://www.facebook.com/timesquareclub">facebook</a></li>
			<li><a href="http://www.instagram.com/timesquareclub">instagram</a></li>
		</ul>

	</div> <!-- /.responsive-nav-container -->
</template>

<script>
export default {
	props: [ "isResNavOpen" ],
	methods:{
		handleCloseBarClick: function(){
			this.$emit('toggleBar');
		},
		handleResNavClick: function(){
			this.$emit('toggleBar');
		}
	},
}
</script>


<style scoped>

li{
  position: relative;
}

li:hover .dropDown{
  display: block;
}

.dropDown{
  position: absolute;
  display: none;
  background-color: white;
  padding: 20px;
  left: -20px;
      box-shadow: 0 15px 30px #00000047;
}

.dropDown li{
  padding: 0;
  margin: 0;
  display: block;
  float: none;
  line-height: 30px;
  z-index: 5;
} 

</style>