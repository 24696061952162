<template>
  <!-- ========== Footer ========== -->

  <footer>
    <div class="container">
      
      <div class="footer-details">
        <ul>
          <li>Visit!</li>
          <li>
            TIME SQUARE RESORT & SPA <br />
            The Villa <br />
            Opp. Suryavarsani School, <br />
            Bhuj- Mundra Road, <br />
            Kutch - Gujarat <br />
            Pincode : 370427
          </li>
        </ul>
      </div>

      <div class="footer-details">
        <ul>
          <li>contact</li>
          <li>Room Reservation: <br /></li>
          <li> ✉ <a href="mailto:reservations@timesquare.in" class="mail-link footer-email">reservations@timesquare.in</a> ✆ <a href="tel:+91 79847 59430">+91-79847 59430</a></li>
          <li>Membership inquiry:</li>
          <li> ✉ <a href="mailto:club@timesquare.in" class="mail-link footer-email">club@timesquare.in</a> ✆ <a href="tel:+91 75791 75791">+91-75791 75791</a> </li>
          <li>Events: </li>
          <li> ✉ <a href="mailto:csm@timesquare.in" class="mail-link footer-email">csm@timesquare.in</a> ✆ <a href="tel:+91 79847 60039">+91-79847 60039</a>  </li>
        </ul>
      </div>

      <div class="footer-details">
        <ul>
          <li>Follow Us</li>
          <li>
            <a href="http://www.instagram.com/timesquareclub">Instagram</a>
          </li>
          <li>
            <a href="http://www.facebook.com/timesquareclub">Facebook</a>
          </li>
        </ul>
      </div>

      <div class="clearfix"></div>

      <div class="terms">
        <div><router-link  to="/PrivacyPolicy">Privacy Policy</router-link></div>
        <div><router-link  to="/RefundPolicy">Refund & Cancellation Policy</router-link></div>
        <div><router-link  to="/TermsConditions">Terms & Conditions</router-link></div>
      </div>
      <p class="copyright">
        Entry for Members and Room guests only 
        <br/>
        Managed By
        <a style="font-weight: 700" href="http://www.nilehospitality.com/"
          >NILE Hospitality</a
        >
      </p>

      

      <p class="copyright">
        copyright &#169; Time Square Resort
        &amp; Spa 2021
      </p>

      
    </div>
    <!-- /.container -->

    <div class="webCopy">
      <div class="container">
        Code &amp; Design | <a href="https://skillhouse.art">Skillhouse</a>
      </div>

      <div class="clearfix"></div>
    </div>
  </footer>
  <!-- /.footer -->
</template>

<style scoped>
.terms {
  margin: 20px 0px;
  color:#555;
}

.terms div {
  
  margin-right: 20px;

}
</style>