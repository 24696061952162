import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";


// Defining Routes with non-conventional paths

import Home from "./views/Home.vue";
import PageNotFound from "./views/PageNotFound.vue";

let routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: PageNotFound,
    }];


// List of conventional pages
let pages = [ "Club", "Dining", "Events", "Contact", "Rooms", "Gymnasium", "Lifestyle","SwimmingPool", "OutdoorSports", "IndoorGames","IndoorSports", "Entertainment", "Golf", "MeetingsWeddings", "Experiences", "SpaHammam", "TermsConditions", "PrivacyPolicy", "RefundPolicy"]

// Import and assign pages in route
for(let page in pages){

	let pageName = pages[page]

	let PageData = () => import("./views/" + pageName + ".vue");

	let pageObject = {
        path: "/" + pageName,
        name: pageName,
        component: PageData,
    }

	let objectId = Object.keys(routes).length;
	
	routes[objectId] = pageObject

}

console.log(routes)

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(function (to, from, next) { 
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 20);
    next();
});

export default router;
