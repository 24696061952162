<template>
  <div class="grid">
    <div class="blank"></div>
    <h1>404</h1>
    <p>The Page you are looking for does not exist or been removed!</p>
    <div class="blank"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  font-family: "DM Serif Text", sans-serif;
  font-size: 12rem;
  color: #af8e57;
}

p {
  text-align: center;
  padding: 30px;
}
.grid {
  display: grid;
  place-items: center;
}

.blank {
  height: 8rem;
}
</style>